import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{` 💌 `}<a parentName="p" {...{
        "href": "mailto://support@biblic.net"
      }}>{`support@biblic.net`}</a>{` or find us on : `}<a parentName="p" {...{
        "href": "https://among.st/"
      }}>{`어몽스트`}</a>{`, `}<a parentName="p" {...{
        "href": "https://learned.kr"
      }}>{`러니드`}</a>{` & `}<a parentName="p" {...{
        "href": "https://sphase.io/"
      }}>{`스페이즈`}</a></p>
    <p>{` 📞 CALL : +82 70-8065-0550 `}</p>
    <p>{` 📠 FAX : +82 50-8931-1888 `}</p>
    <p>{` 📍 111, Anyang-ro, Anyang-si, Gyeonggi-do, South Korea`}</p>
    <p>{` 📍 4F36, 25 Misagangbyeonseo-ro, Hanam-si, Gyeonggi-do, South Korea`}</p>
    <p>{` 🎯`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      