import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Web Service" //  link=""
    bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Reserved Hosting, Domain Register, SSL and Latest News.
    </ProjectCard>
    <ProjectCard title="EdTech" //  link=""
    bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Ready to Use, Dedicated Learning Management System.
    </ProjectCard>
    <ProjectCard title="Contents Hub" //  link=""
    bg="linear-gradient(to right, #321D8C 0%, #9932cc 100%)" mdxType="ProjectCard">
   Beclass, Steamulus, See/sHow. Learning Management System.
    </ProjectCard>
    <ProjectCard title="Agency" //  link=""
    bg="linear-gradient(to right, #008080 0%, #e6e6fa 100%)" mdxType="ProjectCard">
  AI / AR / WEB Contents & Internal/External Software Development
    </ProjectCard>
    <ProjectCard title="Tools" //  link=""
    bg="linear-gradient(to right, #9932cc 0%, #005757 100%)" mdxType="ProjectCard">
  Storage, Drive, Analytics, Shortener, Bulk Domain Search
    </ProjectCard>
    <ProjectCard title="E-Commerce" //  link=""
    bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
   Cross-Border B2C/B2B/D2C E-Commerce platform
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      