import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`BIBLIC was founded 2021 with a mission to facilitate Digital Transformation. From the beginning, we have been committed to universal literacy of technology.`}</p>
    </blockquote>
    <p>{`To make a difference to the family, nation, and the world by Favor.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      